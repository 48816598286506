/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import axiosClient from '../../../api/axiosClient';
import { autoUpdateNewNameGpts, createVectorStore, getlistLmm } from '../../../api/service';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { createBotState } from '../../../atom';
import TemplatePrompt from '../../layout/appsetting/templatePrompt';
import AutoCustomPrompt from '../../layout/appsetting/autoCustomPrompt';

const CreateBotGpts = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [listLlm, setListLlm] = useState(null);
    const { TextArea } = Input;
    const router = useNavigate();
    const [, setIsCreateBot] = useRecoilState(createBotState);

    const prompt = Form.useWatch('instructions', form);
    const apikey = Form.useWatch('openai_api_key', form);

    const onReset = () => {
        setIsCreateBot({
            isOpen: false,
        });
        form.resetFields();
    };

    const optionLlm = (isLlm) => {
        return listLlm
            ?.filter((i) => i.provider === isLlm)[0]
            ?.models?.map((value) => ({ label: value, value: value }));
    };

    const onFinish = async (data) => {
        const lastValueModel = optionLlm('openai')[optionLlm('openai').length - 1].value;
        
        const payload = {
            ...data,
            llm_model: lastValueModel,
            function_tools: [],
            file_ids: [],
            vector_store_ids: [],
            is_code_interpreter: false,
            is_file_search: false,
        };

        const vectoreStore = await createVectorStore(payload);
        if (vectoreStore) {
            payload.vector_store_ids = [vectoreStore?.vector_store_id];
        }

        try {
            const res = await axiosClient.post(`assistant/`, payload);
            if (res.status <= 205) {
                router(`/app-gpts/${res?.data?.id}/setting`);
                onReset();
                message.success('Tạo bot thành công');
            } else if (res.data?.detail) {
                message.error(res.data?.detail);
            } else if (res.data?.msg) {
                message.error(res.data?.msg);
            } else {
                message.error('Tạo bot thất bại');
            }
            setIsLoading(false);
        } catch (error) {
            message.warning('Chức năng bảo trì');
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const getListLlm = async () => {
            const dataLlm = await getlistLmm();
            setListLlm(dataLlm || null);
        };
        getListLlm();
    }, []);

    return (
        <>
            <Form autoComplete="off" layout="vertical" onFinish={onFinish} form={form} className="p-1 pb-0">
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Vui lòng nhập Tên bot!' }]}
                    label="Tên bot"
                    tooltip="Tên hiển thị ở bảng điều khiển của Mindmaid"
                >
                    <Input maxLength={200} showCount placeholder="Ví dụ: Assisant" />
                </Form.Item>
                <Form.Item
                    name="openai_api_key"
                    rules={[{ required: true, message: 'Vui lòng nhập API key!' }]}
                    label="API Key OpenAI"
                    tooltip="API key được cung cấp bởi OpenAI "
                >
                    <Input.Password type="passowrd" placeholder="API key của openAI" />
                </Form.Item>
                <div className="w-full relative">
                    <Form.Item
                        label={
                            <div className="flex items-center">
                                <p>Mô tả</p>
                                <TemplatePrompt type="instructions" form={form} iCreate />
                            </div>
                        }
                        name="instructions"
                        rules={[{ required: true, message: 'Vui lòng nhập Mô tả!' }]}
                        tooltip="Mô tả để bot trả lời theo ý muốn của người dùng."
                    >
                        <TextArea
                            style={{ height: 270, resize: 'none' }}
                            placeholder="Ví dụ: Hãy nhập vai trợ lý ảo tư vấn, bán hàng và cố gắng trả lời câu hỏi của khách hàng ngắn gọn, chính xác nhất. Nội dung trả lời không vượt quá 200 từ. Xưng tôi, gọi khách hàng là quý khách."
                        />
                    </Form.Item>
                    <div className="absolute right-0 top-0">
                        <AutoCustomPrompt type="instructions" iCreate prompt={prompt} apikey={apikey} form={form} />
                    </div>
                </div>
                <Form.Item className="flex  justify-end mb-0">
                    <div className="flex gap-2 items-center">
                        <Button onClick={onReset} className="" htmlType="button">
                            Huỷ bỏ
                        </Button>
                        <Button loading={isLoading} htmlType="submit" className="bg-primary" type="primary">
                            Tạo bot
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    );
};

export default CreateBotGpts;
