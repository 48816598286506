import React, { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import IconVoice from '../icon/voice';
import { MdKeyboardVoice, MdOutlineAttachFile, MdOutlineKeyboardVoice } from 'react-icons/md';
import IconSend from '../icon/send';
import { LuImagePlus } from 'react-icons/lu';
import { useRef } from 'react';
import { PiBroom } from 'react-icons/pi';

const FormInputSend = ({
    onSubmit,
    handleDeleteHistory,
    handleClearInput,
    setFollowup,
    setDataHistory,
    isTyping,
    dataHistory,
    dataBot,
    inputRef,
    question,
    setQuestion,
    listening,
    handleSetHighlightedItem,
    SpeechRecognition,
    resetTranscript,
    setCheckTrainscrip,
    listenContinuously,
    setImageSend,
    imageSend,
    hiddenVoice,
    setErrorImage,
    hidenClearInput,
    disableFunctionality,
    disableForcus,
    isAssistant,
    // IconChat,
    // IconClear,
    // IconVoiceChat,
    // IconImage,
}) => {
    const onChangfile = (event, type) => {
        event.preventDefault();
        const files = event?.target?.files[0];
        if (files) {
            if (files) {
                if (!isAssistant) {
                    // check image
                    if (!files.type.startsWith('image/')) {
                        setErrorImage('Vui lòng chọn một tệp ảnh.');
                        handleCleatFile();
                        return;
                    }
                }

                // check sze image
                if (files.size > 20 * 1024 * 1024) {
                    // 20mb
                    handleCleatFile();
                    setErrorImage('Kích thước ảnh quá lớn. Vui lòng chọn một ảnh nhỏ hơn.');
                    return;
                }
                // set image
                setImageSend(files);
                setErrorImage('');
            }
        }
    };

    const refFile = useRef();

    const onchangeInput = (value) => {
        setQuestion(value);
        inputRef.current.value = value;
        setErrorImage('');

        const textarea = inputRef.current;
        textarea.style.height = '24px';
        // Ensure that the scrollHeight is not greater than 200px
        if (textarea.scrollHeight <= 150 && textarea.scrollHeight > 40 && value?.length > 0) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        } else if (textarea.scrollHeight > 150) {
            textarea.style.height = '150px';
        }
    };
    const handleCleatFile = () => {
        refFile.current.value = '';
    };

    const handlePaste = (e) => {
        const items = (e.clipboardData || e.originalEvent.clipboardData).items;
        for (let index in items) {
            const item = items[index];
            if (item.kind === 'file') {
                const blob = item.getAsFile();
                // const url = URL.createObjectURL(blob);
                setImageSend(blob);
            }
        }
    };

    useEffect(() => {
        if (!imageSend) handleCleatFile();
    }, [imageSend]);

    return (
        <form onSubmit={onSubmit} className="flex items-center gap-1 justify-between">
            <div
                className={twMerge(
                    'flex items-center self-end duration-300 min-h-[40px] ease-in-out cursor-pointer',
                    disableFunctionality && 'cursor-not-allowed',
                )}
                onClick={() => {
                    if (!isTyping && !disableFunctionality) {
                        handleDeleteHistory();
                        handleClearInput();
                        setFollowup([]);
                        setTimeout(() => {
                            setDataHistory([]);
                        }, 300);
                    }
                }}
            >
                <PiBroom
                size={18}
                    style={{
                        fill: dataHistory?.length >= 1 && !isTyping && dataBot?.preview_send_button_color,
                    }}
                />
            </div>
            <div className="flex flex-col p-1 overflow-hidden rounded-3xl 2xl:p-2 hover-shadow w-full">
                <div className={twMerge('flex relative ')}>
                    <textarea
                        onPaste={handlePaste}
                        ref={inputRef}
                        name="question"
                        disabled={disableFunctionality}
                        defaultValue={question}
                        onChange={(e) => onchangeInput(e.target.value)}
                        autoFocus={false}
                        autoComplete="off"
                        onKeyDown={(event) => handleSetHighlightedItem({ event })}
                        className="w-full text-gray-900 bg-white text-[15px] focus:outline-none pr-3 h-6 resize-none  disable-arrow-textarea thin-scroll"
                        placeholder={listening ? ' ' : imageSend ? 'Đặt câu hỏi về ảnh' : 'Mời bạn đặt câu hỏi'}
                    />
                    {!isAssistant && (
                        <label
                            htmlFor="send-image"
                            className={twMerge('mt-[2px] cursor-pointer', isTyping && 'cursor-not-allowed')}
                        >
                            <LuImagePlus size={22} className="text-gray-400" />
                        </label>
                    )}
                    {isAssistant && (
                        <label
                            htmlFor="send-image"
                            className={twMerge('mt-[2px] cursor-pointer', isTyping && 'cursor-not-allowed')}
                        >
                            <MdOutlineAttachFile size={22} className="text-gray-400 rotate-45" />
                        </label>
                    )}
                    <input
                        disabled={disableFunctionality}
                        ref={refFile}
                        onChange={(e) => onChangfile(e)}
                        type="file"
                        id="send-image"
                        name="send-image"
                        accept={
                            isAssistant
                                ? '.c, .cpp, .csv, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .jpeg, .jpg, .js, .gif, .png, .tar, .ts, .xml, .zip'
                                : '.png, .jpeg, .jpg'
                        }
                        // accept=".png, .jpeg, .jpg"
                        hidden
                    />
                    {!hiddenVoice && (
                        <>
                            {listening && question === '' && <IconVoice />}
                            <div className={twMerge('ml-2 cursor-pointer', isTyping && 'cursor-not-allowed')}>
                                {listening ? (
                                    <span
                                        onClick={() => {
                                            SpeechRecognition.stopListening();
                                            resetTranscript();
                                            setCheckTrainscrip(false);
                                        }}
                                    >
                                        <MdKeyboardVoice size={24} className={`text-blue-400`} />
                                    </span>
                                ) : (
                                    <span onClick={listenContinuously}>
                                        <MdOutlineKeyboardVoice size={24} className={`text-gray-400`} />
                                    </span>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <button
                type="submit"
                disabled={disableFunctionality}
                style={{
                    background: dataBot?.preview_send_button_color && !isTyping && dataBot?.preview_send_button_color,
                }}
                className={`text-white ${
                    isTyping ? 'bg-gray-400' : 'bg-[#028AFF]'
                } focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-1.5`}
            >
                <div className="relative flex items-center justify-center">
                    <IconSend />
                </div>
            </button>
        </form>
    );
};

export default FormInputSend;
