import React from 'react';

const IconSend = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 27 27" fill="none">
            <rect width="25.8947" height="25.8947" transform="translate(0.552734 0.552612)" fill="" />
            <path
                d="M11.6672 15.3288L22.242 4.75778M11.9677 15.8041L14.3656 20.5999C14.9451 21.7588 15.2348 22.3383 15.5998 22.4937C15.9166 22.6285 16.2787 22.6041 16.5745 22.428C16.9153 22.225 17.1247 21.6119 17.5434 20.3857L22.074 7.11736C22.4389 6.04892 22.6213 5.5147 22.4964 5.16131C22.3878 4.85387 22.146 4.61204 21.8385 4.50342C21.4851 4.37856 20.9509 4.56098 19.8825 4.92581L6.61412 9.45647C5.3879 9.87517 4.7748 10.0845 4.57183 10.4254C4.3957 10.7212 4.37129 11.0833 4.50614 11.4C4.66153 11.765 5.241 12.0548 6.39993 12.6342L11.1957 15.0321C11.3867 15.1276 11.4821 15.1753 11.5649 15.2391C11.6383 15.2957 11.7041 15.3615 11.7607 15.4349C11.8245 15.5177 11.8722 15.6132 11.9677 15.8041Z"
                stroke="white"
                strokeWidth="1.43023"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconSend;
