import React from 'react';

const IconVoice = () => {
    return (
        <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <div className="flex items-center justify-center text-[18px] h-5">
                <svg width="86" height="32" viewBox="0 0 86 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="15" width="2" height="2" rx="1" fill="#2D84FD" />
                    <rect x="6" y="12" width="2" height="8" rx="1" fill="#2D84FD" />
                    <rect x="12" y="9" width="2" height="14" rx="1" fill="#2D84FD" />
                    <rect x="18" y="14" width="2" height="4" rx="1" fill="#2D84FD" />
                    <rect x="24" y="8" width="2" height="16" rx="1" fill="#2D84FD" />
                    <rect x="30" y="9" width="2" height="14" rx="1" fill="#2D84FD" />
                    <rect x="36" y="11" width="2" height="10" rx="1" fill="#2D84FD" />
                    <rect x="42" y="11" width="2" height="10" rx="1" fill="#2D84FD" />
                    <rect x="48" y="11" width="2" height="10" rx="1" fill="#2D84FD" />
                    <rect x="54" y="9" width="2" height="14" rx="1" fill="#2D84FD" />
                    <rect x="60" y="11" width="2" height="10" rx="1" fill="#2D84FD" />
                    <rect x="66" y="8" width="2" height="16" rx="1" fill="#2D84FD" />
                    <rect x="72" y="11" width="2" height="10" rx="1" fill="#2D84FD" />
                    <rect x="78" y="14" width="2" height="4" rx="1" fill="#2D84FD" />
                    <rect x="84" y="15" width="2" height="2" rx="1" fill="#2D84FD" />
                </svg>
            </div>
        </div>
    );
};

export default IconVoice;
