import React, { useCallback, useEffect, useState } from 'react';
import { Button, Drawer, Space, Row, Col, Card, Typography, Avatar, Tag, message, Tooltip } from 'antd';
import { useRecoilState } from 'recoil';
import { storeBotState } from '../../../atom';
import { getStoreBotDetail } from '../../../api/service';
import { getInfobot } from '../../../api/service/chatService';
import DrawerChidren from './drawerChidren';
import { createBotStore, getPayment } from '../../../api/service/storeBot';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getCookie } from '../../../utils/cookie';
import { decodeToken } from 'react-jwt';
import Loading from '../../base/loading';
import BotDemoAndDebugMindmaid from '../../bot/demoMindmaid';
const { Title, Paragraph } = Typography;

const DrawerStoreBot = React.memo(() => {
    const [data, setData] = useRecoilState(storeBotState);
    const [dataBot, setDataBot] = useState(null);
    const [dataStoreBot, setDataStoreBot] = useState(null);
    const [loading, setLoading] = useState({ getBot: false, getStore: false, post: false, getPayment: false });
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const [searchParams] = useSearchParams();
    const idPayment = searchParams?.get('payment');
    const router = useNavigate();
    const isToken = decodeToken(getCookie('access_token'));
    const showChildrenDrawer = () => {
        setChildrenDrawer(true);
    };
    const onChildrenDrawerClose = () => {
        setChildrenDrawer(false);
    };
    const onClose = useCallback(() => {
        setData({ data: null, open: false });
        setDataBot(null);
        setDataStoreBot(null);
    }, [setData]);

    const fetchInfoBot = useCallback(async (botId) => {
        setLoading((prev) => ({ ...prev, getBot: true }));
        const response = await getInfobot(botId);
        setDataBot(response);
        setLoading((prev) => ({ ...prev, getBot: false }));
    }, []);

    useEffect(() => {
        const fetchData = async (storeId) => {
            setLoading((prev) => ({ ...prev, getStore: true }));
            const response = await getStoreBotDetail(storeId);
            setDataStoreBot(response);
            fetchInfoBot(response?.bot);
            setLoading((prev) => ({ ...prev, getStore: false }));
        };

        if (data?.open) {
            if ((idPayment && idPayment !== 'false' && idPayment !== 'null') || data?.data?.id) {
                const storeId = idPayment && idPayment !== 'false' && idPayment !== 'null' ? idPayment : data?.data?.id;
                fetchData(storeId);
            }
        }
    }, [data?.data?.id, data?.data?.bot, data?.open, idPayment, dataStoreBot?.bot, fetchInfoBot]);

    const getUrlPayment = useCallback(async () => {
        setLoading((prev) => ({ ...prev, getPayment: true }));
        const response = await getPayment(data?.data?.id);
        if (response?.success) {
            window.open(response?.data?.payment_url, '_blank');
        } else {
            if (response?.data?.data?.detail) {
                message.error(response?.data?.data?.detail);
            } else {
                message.error('Đã có lỗi sảy ra. Vui lòng thể thử lại sau');
            }
        }
        setLoading((prev) => ({ ...prev, getPayment: false }));
    }, [data?.data?.id]);

    const handleNextDrawer = useCallback(() => {
        if (isToken?.user_id) {
            if (
                (!dataStoreBot?.is_purchase && dataStoreBot?.has_paid) ||
                (!dataStoreBot?.is_purchase && !dataStoreBot?.has_paid)
            ) {
                showChildrenDrawer();
            } else {
                getUrlPayment();
            }
        } else {
            router('/login');
            onChildrenDrawerClose();
            setData((prev) => ({ data: null, open: false }));
        }
    }, [dataStoreBot?.has_paid, dataStoreBot?.is_purchase, getUrlPayment, isToken?.user_id, router, setData]);

    const handleCreate = useCallback(
        async (values) => {
            setLoading((prev) => ({ ...prev, post: true }));
            values = { ...values, id_bot_store: data?.data?.id };
            const response = await createBotStore(values);
            setLoading((prev) => ({ ...prev, post: false }));

            if (response?.success) {
                message.success(response?.data?.detail);
                setChildrenDrawer(false);
                setData((prev) => ({ data: null, open: false }));
                router(`/app/${response?.data?.id}/setting`);
            } else {
                if (response?.data?.data?.detail) {
                    message.error(response?.data?.data?.detail);
                } else {
                    message.error('Dã có lỗi sảy ra. Vui lòng thử lại sau');
                }
            }
        },
        [data?.data?.id, router, setData],
    );

    useEffect(() => {
        if (idPayment !== 'false' && idPayment?.length > 5) {
            message.success('Thanh toán thành công!');
            setData((prev) => ({ data: null, open: true }));
            setTimeout(() => {
                showChildrenDrawer();
            }, 200);
        }
        if (idPayment === 'false') {
            message.error('Thanh toán thất bại! Vui lòng thử lại');
        }
    }, [idPayment, setData]);

    return (
        <>
            <Drawer
                title={`Tạo mới từ bot ${data?.data?.name || dataStoreBot?.name}`}
                width={childrenDrawer ? 780 : 900}
                onClose={onClose}
                open={data?.open}
                bodyStyle={{
                    padding: 0,
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Huỷ bỏ</Button>
                        <Button loading={loading?.getPayment} type="primary" onClick={handleNextDrawer}>
                            {dataStoreBot?.is_purchase && !dataStoreBot?.has_paid ? 'Thanh toán' : 'Tạo ngay'}
                        </Button>
                    </Space>
                }
            >
                <div className="bg-slate-100 h-full relative" style={{ padding: 24 }}>
                    {(loading?.getBot || loading?.getStore) ? (
                        <Loading />
                    ) : (
                        <Row gutter={[24, 16]}>
                            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                <div className="h-[calc(100vh-120px)] !shadow-none overflow-hidden rounded-2xl">
                                    <BotDemoAndDebugMindmaid
                                        isDebug={false}
                                        dataBot={dataBot}
                                        bot_id={dataStoreBot?.bot || dataBot?.id}
                                        loading={false}
                                    />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                                <div className="h-[calc(100vh-120px)] overflow-y-auto thins-croll">
                                    <Card
                                        style={{ width: '100%', height: '100%', minHeight: 400 }}
                                        className="!rounded-2xl !shadow-sm !border-none"
                                    >
                                        <Card.Meta
                                            avatar={
                                                <Avatar
                                                    size={40}
                                                    className="bg-blue60"
                                                    shape="square"
                                                    src={dataStoreBot?.preview_logo}
                                                    alt="avatar"
                                                />
                                            }
                                            title={
                                                <Tooltip title={dataStoreBot?.name}>
                                                    <Title level={4} className="truncate">
                                                        {dataStoreBot?.name}
                                                    </Title>
                                                </Tooltip>
                                            }
                                            description={<></>}
                                        />
                                        <div className="pt-2">
                                            <Paragraph>
                                                <b className="font-medium text-gray-60">Model:</b>
                                                <p>
                                                    <Tag color="processing">{dataStoreBot?.source_llm}</Tag>
                                                </p>
                                            </Paragraph>
                                            <Paragraph>
                                                <b className="font-medium text-gray-600">Giá tiền:</b>{' '}
                                                <p>
                                                    {dataStoreBot?.is_purchase ? (
                                                        `${dataStoreBot?.price.toLocaleString()} VNĐ`
                                                    ) : (
                                                        <Tag color="success">Miễn phí</Tag>
                                                    )}{' '}
                                                </p>
                                            </Paragraph>
                                            <Paragraph>
                                                <b className="font-medium text-gray-600">Người tạo:</b>{' '}
                                                <p>
                                                    <Tag color="">{dataStoreBot?.created_by}</Tag>
                                                </p>
                                            </Paragraph>
                                            <Paragraph>
                                                <b className="font-medium text-gray-600">Nội dung:</b>{' '}
                                                <p className=" font-light">{dataStoreBot?.description}</p>
                                            </Paragraph>
                                            <Paragraph>
                                                <b className="font-medium text-gray-600">Ngày tạo:</b>
                                                <p className=" font-light">
                                                    {new Date(dataStoreBot?.created_at).toLocaleDateString()}
                                                </p>
                                            </Paragraph>
                                        </div>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    )}
                </div>
                <DrawerChidren
                    childrenDrawer={childrenDrawer}
                    loading={loading.post}
                    handleCreate={handleCreate}
                    onChildrenDrawerClose={onChildrenDrawerClose}
                />
            </Drawer>
        </>
    );
});

export default DrawerStoreBot;
