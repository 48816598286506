import { SSE } from '../connectSSE/index';
const baseURL = process.env.REACT_APP_API_V3_URL;

// check json
export const testJSON = (text) => {
    if (typeof text !== 'string') {
        return false;
    }
    try {
        var json = JSON.parse(text);
        return typeof json === 'object';
    } catch (error) {
        return false;
    }
};

// error
export const errorCommonStreaming = (e, no_money_notice) => {
    const defultError = 'Đã có lỗi xảy ra. Vui lòng thử lại.';
    let err = null;
    try {
        err = JSON.parse(e.data);
    } catch (error) {
        err = null;
    }

    return err?.msg || no_money_notice || defultError;
};

//call sse

export const createSSE = (requestData, jwt) => {
    const formData = new FormData();
    for (const key in requestData) {
        formData.append(key, requestData[key]);
    }
    let jwtPayload = null;

    if (jwt?.includes('"')) {
        jwtPayload = jwt?.slice(1, -1);
    } else {
        jwtPayload = jwt;
    }
    return new SSE(`${baseURL}/v1/answers`, {
        headers: {
            // Remove Content-Type header for FormData, it will be set automatically
            Authorization: `Bearer ${jwtPayload || ''}`,
        },

        method: 'POST',
        payload: formData,
    });
};

// create sss
export const createSSEAssistant = (requestData) => {
    const formData = new FormData();
    for (const key in requestData) {
        formData.append(key, requestData[key]);
    }
    return new SSE(`${baseURL}/api/assistant-answer`, {
        headers: {
            // Remove Content-Type header for FormData, it will be set automatically
        },

        method: 'POST',
        payload: formData,
    });
};
