import React from 'react';
import { motion } from 'framer-motion';
import { FaArrowRightLong } from 'react-icons/fa6';

const FlowupEmbed = ({ followup, isTyping, dataBot, followupQuestion, ...props }) => {
    return (
        followup?.length > 0 &&
        !isTyping && (
            <div {...props} className="ml-[52px] mt-4 sm:ml-[50px] pb-10">
                <div className="relative flex flex-col justify-start flex-wrap gap-1 mb-2 custom w-fit max-w-[100%] mr-3.5 ">
                    {followup?.map((data, index) => (
                        <motion.div
                            key={index}
                            initial="initial"
                            whileInView="animate"
                            variants={{
                                initial: {
                                    y: '30%',
                                    opacity: 0,
                                    transition: { duration: 0.1, ease: 'easeInOut' },
                                },
                                animate: {
                                    y: 0,
                                    opacity: 1,
                                    transition: {
                                        duration: 1,
                                        ease: 'easeInOut',
                                    },
                                },
                            }}
                            className={`px-5 py-2 mb-2 w-fit cursor-pointer mx-0 rounded-3xl`}
                            style={{
                                background: dataBot?.botM_backgroundColor ? dataBot?.botM_backgroundColor : 'rgb(237, 242, 247)',
                                color: dataBot?.botM_color && dataBot?.botM_color,
                            }}
                            viewport={{ once: true }}
                        >
                            <div
                                style={{
                                    color: dataBot?.botM_color && dataBot?.botM_color,
                                }}
                                key={index}
                                onClick={() => followupQuestion(data.question, '', '')}
                                id="button"
                                className="w-full font-inter text-[13px] text-left text-gray-500 "
                            >
                                {data?.question}
                            </div>
                        
                        </motion.div>
                    ))}

                </div>
            </div>
        )
    );
};

export default FlowupEmbed;
