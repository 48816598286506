/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from 'react';
import { Form, Input, Button, message, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../../api/axiosClient';
// import { extractAndRemoveLink, extractLink } from '../../../utils/custom';
import { createFormPersonalization } from '../../../api/service';
import AutoCustomPrompt from '../../layout/appsetting/autoCustomPrompt';
import { useRecoilState } from 'recoil';
import { createBotState } from '../../../atom';
import TemplatePrompt from '../../layout/appsetting/templatePrompt';
// import { hasLink } from '../../../utils/sorting';

const CreateBotMindmaid = () => {
    const [loader, setLoader] = useState(false);
    const history = useNavigate();
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [, setIsCreateBot] = useRecoilState(createBotState);

    const prompt = Form.useWatch('system_prompt', form);
    const apikey = Form.useWatch('user_api_key', form);

    const onReset = () => {
        setIsCreateBot({
            type: '',
            isOpen: false,
        });
        form.resetFields();
    };

    const onFinish = async (values) => {
        const data = values;
        setLoader(true);

        const payload = {
            name: data.name,
            datasets: [{ name: 'Dữ liệu huấn luyện', isAnswer: true }],
            type_dataset: 0,
            isPublic: false,
            isMessage: 'Xin chào bạn!',
            user_api_key: data.user_api_key ? data.user_api_key : '',
            system_prompt: data?.system_prompt,
            boxChat_title: data.name
        };

        try {
            const res = await axiosClient.post('bots/', payload);
            if (res.status === 200 || res.status === 201) {
                message.success('Đã tạo thành công bot');
                onReset();
                history(`/app/${res?.data?.id}/setting`);
                createFormPersonalization(res.data?.id);
            } else {
                if (res.data?.detail) {
                    message.error(res.data?.detail)
                    // const errorJSON = res.data?.detail?.split(' - ');
                    // const validJsonString = errorJSON[1]?.replace(/'/g, '"').replace(/None/g, 'null');
                    // const errorObject = JSON.parse(validJsonString);
                    // if (hasLink(errorObject?.error?.message)) {
                    //     notification.open({
                    //         message: extractAndRemoveLink(errorObject?.error?.message),
                    //         description: (
                    //             <a
                    //                 className="border-b text-sm  text-primary"
                    //                 href={extractLink(errorObject?.error?.message)}
                    //                 target="_blank"
                    //                 rel="noreferrer"
                    //             >
                    //                 {extractLink(errorObject?.error?.message) || ''}
                    //             </a>
                    //         ),
                    //     });
                    // } else {
                    //     message.error(errorObject?.error?.message);
                    // }
                } else {
                    message.error('Đã có lỗi xảy ra');
                }
            }
            setLoader(false);
        } catch (error) {
            setLoader(false);
            message.warning('Chức năng đang bảo trì');
        }
    };

    return (
        <Form layout="vertical" onFinish={onFinish} form={form} className="p-1 pb-0">
            <Form.Item
                name="name"
                rules={[{ required: true, message: 'Vui lòng nhập Tên bot!' }]}
                label="Tên bot"
                tooltip="Tên hiển thị ở bảng điều khiển của Mindmaid"
            >
                <Input maxLength={200} showCount placeholder="Ví dụ: Mindmaid " />
            </Form.Item>
            <Form.Item
                name="user_api_key"
                rules={[{ required: true, message: 'Vui lòng nhập API key!' }]}
                label="API Key OpenAI"
                tooltip="API key được cung cấp bởi OpenAI "
            >
                <Input.Password name="user_api_key" type="passowrd" placeholder="API key của openAI" />
            </Form.Item>
            <div className="w-full relative">
                <Form.Item
                    label={
                        <div className='flex items-center'>
                            <p>Lệnh điều khiển</p>
                            <TemplatePrompt type='system_prompt' form={form} iCreate />
                        </div>
                    }
                    name="system_prompt"
                    rules={[{ required: true, message: 'Vui lòng nhập Lệnh điều khiển!' }]}
                    tooltip="Lệnh điều khiển để bot trả lời theo ý muốn của người dùng."
                >
                    <TextArea
                        style={{ height: 270, resize: 'none' }}
                        placeholder="Ví dụ: Hãy nhập vai trợ lý ảo tư vấn, bán hàng và cố gắng trả lời câu hỏi của khách hàng ngắn gọn, chính xác nhất. Nội dung trả lời không vượt quá 200 từ. Xưng tôi, gọi khách hàng là quý khách."
                    />
                </Form.Item>
                <div className="absolute right-0 top-0">
                    <AutoCustomPrompt type="system_prompt" iCreate prompt={prompt} apikey={apikey} form={form} />
                </div>
            </div>

            <Form.Item className="flex  justify-end mb-0">
                <div className="flex gap-2 items-center">
                    <Button onClick={onReset} className="" htmlType="button">
                        Huỷ bỏ
                    </Button>
                    <Button loading={loader} htmlType="submit" className="bg-primary" type="primary">
                        Tạo bot
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};

export default CreateBotMindmaid;
