/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import logoEmbed from '../../../../assets/images/logoembed.svg';
import { twMerge } from 'tailwind-merge';
import Typing from '../../../common/Typing';
import { BsPlayFill } from 'react-icons/bs';
import IconTab from '../icon/tab';
import { Avatar, Image, message, Space, Tooltip } from 'antd';
// import MarkdownRender from '../../../base/markdown';
import { FaRegFileAlt } from 'react-icons/fa';
import { TbPrompt } from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import { IoCopyOutline } from 'react-icons/io5';
import { useRecoilState } from 'recoil';
import { promptDebugState } from '../../../../atom';
import { Markdown } from '../../../base/markdown/markdown';
import TableProduct from '../../TableProduct';
import { convertToPreview } from '../../../../utils/validatetor';

const ResponseMesenger = ({ dataHistory, dataBot }) => {
    const [visibleSources, setVisibleSources] = useState([]);
    const { page } = useParams();
    const [, setShowPrompt] = useRecoilState(promptDebugState);

    const handleSourcesToggle = (itemId) => {
        if (visibleSources.includes(itemId)) {
            setVisibleSources(visibleSources.filter((id) => id !== itemId));
        } else {
            setVisibleSources([...visibleSources, itemId]);
        }
    };

    const copyToClipBoard = async (str) => {
        try {
            await navigator.clipboard.writeText(str);
            message.success('Đã sao chép');
        } catch (err) {
            message.error('Sao chép thất bại');
        }
    };

    function isLinkValid(link) {
        // Kiểm tra xem chuỗi có đúng là một đường link không
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        if (!urlPattern.test(link)) {
            return false;
        }
        // Kiểm tra xem đường link có thể sử dụng được không
        const url = new URL(link);
        if (url.protocol !== 'http:' && url.protocol !== 'https:') {
            return false;
        }
        // Nếu tất cả điều kiện đều đúng, đường link được coi là hợp lệ và có thể sử dụng.

        return true;
    }

    return dataHistory?.map((item, index) => {
        return (
            <div key={index}>
                <div className="flex flex-col gap-2 m-3 space-y-5" key={index}>
                    {(item?.question || item?.sendImage || item?.file_question) && (
                        <div
                            className={twMerge(
                                'self-end bg-[#028AFF] text-[14px] font-light w-fit max-w-[100%] rounded-3xl px-4 py-2.5 text-white',
                                !item?.question && item?.sendImage && 'p-1',
                                (item?.sendImage || item?.file_question) && '!bg-transparent pr-0',
                            )}
                            style={{
                                background: dataBot?.userM_backgroundColor ? dataBot?.userM_backgroundColor : '#028AFF',
                                color: dataBot?.userM_color ? dataBot?.userM_color : 'white',
                            }}
                        >
                            {item?.sendImage && (
                                <div className="w-full flex justify-end">
                                    <div
                                        className={twMerge(
                                            '!items-end rounded-3xl ml-auto w-1/2',
                                            !item?.question && item?.sendImage && 'mt-0',
                                        )}
                                    >
                                        <Image src={item?.sendImage} className="!rounded-lg" alt="" />
                                    </div>
                                </div>
                            )}
                            {item?.file_question && (
                                <>
                                    {item?.file_question?.type?.includes('image') ? (
                                        <div className="w-full flex justify-end">
                                            <div
                                                className={twMerge(
                                                    '!items-end rounded-3xl ml-auto w-1/2',
                                                    !item?.question && item?.sendImage && 'mt-0',
                                                )}
                                            >
                                                <Image
                                                    src={URL.createObjectURL(item?.file_question)}
                                                    className="!rounded-lg"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="w-full flex justify-end">
                                            <div className="flex items-center gap-1">
                                                <FaRegFileAlt
                                                    size={30}
                                                    className=" bg-blue60 p-1 text-blue-500 rounded"
                                                />
                                                <span className="max-w-[250px] truncate text-black">
                                                    {item?.file_question?.name}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            {item?.question && (
                                <div className="w-full flex justify-end h-auto">
                                    <p
                                        style={{
                                            background: dataBot?.userM_backgroundColor
                                                ? dataBot?.userM_backgroundColor
                                                : '#028AFF',
                                            color: dataBot?.userM_color ? dataBot?.userM_color : 'white',
                                            whiteSpace: 'pre-wrap', // Giữ nguyên khoảng trắng và xuống dòng
                                            wordWrap: 'break-word', // Ngắt từ để không vượt quá kích thước container
                                        }}
                                        className={twMerge(
                                            (item?.sendImage || item?.file_question) &&
                                                'w-fit  rounded-3xl !items-end mt-2 !text-nomarl2 px-4 py-2.5',
                                            '!h-auto  ',
                                        )}
                                    >
                                        {item?.question}
                                    </p>
                                </div>
                            )}
                        </div>
                    )}

                    <div>
                        {item.answer ? (
                            <div className="flex gap-2 max-w-[100%]">
                                <div className="flex flex-col-reverse w-[34px]">
                                    <Avatar
                                        size={30}
                                        className="p-0"
                                        src={dataBot?.preview_logo ? dataBot?.preview_logo : logoEmbed}
                                        alt="logo mindmaid"
                                    />
                                </div>
                                <div className="w-fit max-w-[100%] relative group">
                                    {page?.includes('setting') && (
                                        <div className="absolute -bottom-5 right-0 z-30 group-hover:block hidden">
                                            {item?.data_table && item?.data_table?.length > 0 && (
                                                <Space className="mr-2">
                                                    <TableProduct
                                                        data={item?.data_table}
                                                        classNameItem="font-medium cursor-pointer text-xs text-gray-600"
                                                        className="text-gray-600 mr-2 font-medium cursor-pointer text-xs py-1 px-2 bg-white rounded-lg shadow-md"
                                                    />
                                                </Space>
                                            )}
                                            <Space
                                                onClick={() => copyToClipBoard(item?.answer)}
                                                className="text-gray-600 mr-2 font-medium cursor-pointer text-xs py-1 px-2 bg-white rounded-lg shadow-md"
                                            >
                                                <Tooltip title="Sao chép">
                                                    <IoCopyOutline size={15} />
                                                </Tooltip>
                                            </Space>
                                            {item?.prompt && (
                                                <Space
                                                    onClick={() =>
                                                        setShowPrompt({
                                                            loading: false,
                                                            prompt: item?.prompt,
                                                            isOpen: true,
                                                            question: item?.question,
                                                        })
                                                    }
                                                    className="text-gray-600 font-medium cursor-pointer text-xs py-1 px-2 bg-white rounded-lg shadow-md"
                                                >
                                                    <TbPrompt size={15} />
                                                    <p>Xem prompt</p>
                                                </Space>
                                            )}
                                        </div>
                                    )}

                                    <div
                                        className={twMerge(
                                            ' self-start bg-[#F5F5F5] ] text-[14px] font-light rounded-3xl px-4 py-2.5 shadow',
                                            !item.answer && 'bg-white ',
                                        )}
                                        style={{
                                            background: dataBot?.botM_backgroundColor
                                                ? dataBot?.botM_backgroundColor
                                                : 'white',
                                            color: dataBot?.botM_color ? dataBot?.botM_color : 'black',
                                        }}
                                    >
                                        <Markdown content={item?.answer} />
                                        {item?.source?.length > 0 && (
                                            <div className="mt-2">
                                                <button
                                                    onClick={() => handleSourcesToggle(item?.id)}
                                                    className="text-[#505050] text-[13px] flex items-center gap-1 "
                                                >
                                                    <BsPlayFill
                                                        size={14}
                                                        className={`${
                                                            visibleSources?.includes(item?.id) && ' rotate-90 ease-out'
                                                        }`}
                                                    />
                                                    Hiển thị nguồn
                                                </button>
                                                {visibleSources?.includes(item?.id) && (
                                                    <div className="ml-[2px]">
                                                        {item?.source?.map((result, index) =>
                                                            isLinkValid(result) ? (
                                                                <a
                                                                    target="_blank"
                                                                    key={index}
                                                                    href={result}
                                                                    className="flex items-center gap-1 text-[13px] text-[#3071FF] mb-[2px]"
                                                                    rel="noreferrer"
                                                                >
                                                                    <IconTab />
                                                                    <div className="mb-[1px] hidden-text-one w-full max-w-[250px]">
                                                                        {result}
                                                                    </div>
                                                                </a>
                                                            ) : (
                                                                <div
                                                                    className="mb-[2px] hidden-text-one w-full text-[13px] max-w-[250px]"
                                                                    style={{
                                                                        color:
                                                                            dataBot?.botM_color && dataBot?.botM_color,
                                                                    }}
                                                                >
                                                                    {result}
                                                                </div>
                                                            ),
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {item?.data_table && item?.data_table?.length > 0 && (
                                            <TableProduct data={item?.data_table} />
                                        )}
                                    </div>
                                    {item?.images?.length > 0 && (
                                        <div className="flex flex-col">
                                            <div
                                                className={twMerge(
                                                    'flex my-2 flex-wrap  bg-white p-1 shadow',
                                                    item?.images?.length < 2 && 'w-fit',
                                                )}
                                            >
                                                {item?.images?.map((resultImage, index) => (
                                                    <div
                                                        key={index}
                                                        className={twMerge(
                                                            'w-1/3 px-1 sm:max-w-full xl:max-w-[200px]',
                                                            item?.images?.length < 2 && 'w-full',
                                                        )}
                                                    >
                                                        <div className="rounded m-1  sm:m-0 w-fullcursor-pointer">
                                                            <a target="_blank" href={resultImage} rel="noreferrer">
                                                                <img
                                                                    className="object-cover w-full sm:h-[75px] xl:h-[100px] sm:max-h-[80px] xl:max-h-[180px] rounded"
                                                                    src={resultImage}
                                                                    alt="answer images"
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {item?.videos?.length > 0 && (
                                        <div className="flex flex-col">
                                            <div className="flex flex-wrap my-2">
                                                {item?.videos?.map((result, index) => (
                                                    <div key={index} className="w-full">
                                                        <div className=" p-2 sm:w-full w-full rounded m-1 sm:m-0 hover:bg-blue-400 shadow cursor-pointer hover:shadow bg-white">
                                                            <iframe
                                                                width="100%"
                                                                height="100%"
                                                                src={convertToPreview(result || '')}
                                                                title="YouTube video player"
                                                                frameborder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                referrerpolicy="strict-origin-when-cross-origin"
                                                                allowfullscreen
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-center gap-2">
                                <Avatar
                                    size={30}
                                    src={dataBot?.preview_logo ? dataBot?.preview_logo : logoEmbed}
                                    alt="logo mindmaid"
                                />
                                <Typing color={dataBot?.botM_backgroundColor} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    });
};

export default ResponseMesenger;
