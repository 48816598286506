import React from 'react';
import { HiOutlineChat } from 'react-icons/hi';
import { twMerge } from 'tailwind-merge';

const SuggestQuesiton = ({
    suggest,
    question,
    highlightedItem,
    setSuggest,
    setHighlightedItem,
    followupQuestion,
    handleSetHighlightedItem,
}) => {
    return (
        suggest?.length !== 0 &&
        question !== '' && (
            <ul className="flex flex-col p-3 px-0 pt-0 mb-2 border-b border-gray-300 border-solid ">
                {suggest?.map((data, index) => (
                    <div
                        className={twMerge(
                            'flex items-center p-1 rounded gap-2 border-black cursor-pointer  hover:bg-blue-100 ',
                            highlightedItem === index && 'bg-blue-100',
                        )}
                        key={index}
                        onClick={() => {
                            setSuggest([]);
                            followupQuestion(data.question);
                        }}
                        onMouseEnter={() => handleSetHighlightedItem({ index })}
                        onMouseLeave={() => setHighlightedItem(-1)}
                    >
                        <HiOutlineChat className="w-[15px] font-light" />
                        <div className="flex-1 w-fit font-light text-[13px]">{data.question}</div>
                    </div>
                ))}
            </ul>
        )
    );
};

export default SuggestQuesiton;
