import sign from 'jwt-encode';
import { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Row, Select, Alert } from 'antd';
import axiosClient from '../../../api/axiosClient';
import { decodeToken } from 'react-jwt';
import axios from 'axios';
import { convertObjToArr } from '../../../utils/sorting';
import Loading from '../../base/loading';

const StylePersonzalion = ({
    botId,
    dataBot = null,
    setIFormProfile,
    handleStateNewChat,
    typeStore = 'embedding',
    setIsTyping,
    setUserInfo,
    iFormProfile,
}) => {
    const [form] = Form.useForm();
    const [loader, setLoader] = useState(false);
    const [, setOptions] = useState([]);
    const [configInfo, setConfigInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [notiText, setNotiText] = useState({ text: '', type: '' });
    let jwtSecret = process.env.REACT_APP_JWTSECRET;
    let infoUser = null;

    // get user local
    try {
        const userData = localStorage.getItem(`login-${typeStore}-${botId}`);
        if (userData) {
            infoUser = decodeToken(userData);
        }
    } catch (error) {
        infoUser = null;
    }

    // get token local
    let jwtToken = null;
    try {
        jwtToken = localStorage.getItem(
            typeStore === 'embedding' ? `jwt-${botId}` : typeStore === 'debug' ? `jwt-debug-${botId}` : `jwtp-${botId}`,
        );
    } catch (error) {
        jwtToken = null;
    }

    // send personalizaion
    const onSubmit = async (data) => {
        if (loader) return;
        setLoader(true);
        const dataFilter = [];

        for (const [key, value] of Object.entries(data)) {
            if (key.includes('content-')) {
                const id = +key.replace('content-', '');
                const findItem = configInfo?.detail_preferences?.find((item) => item.id === id);
                dataFilter.push({
                    id,
                    input_value: findItem?.input_value === 'NUMBER' ? +value : value,
                    title: findItem?.title,
                });
            }
            if (key.includes('options-')) {
                const id = +key.replace('options-', '');
                const findItem = configInfo?.detail_preferences?.find((item) => item.id === id);
                let optionValue = [];

                if (Array.isArray(value)) {
                    optionValue = value
                        .map((option) => {
                            const optionStr = option.value;

                            // Kiểm tra nếu optionStr là một chuỗi và chứa dấu phẩy
                            if (typeof optionStr === 'string' && optionStr.includes(',')) {
                                // Tìm kiếm cụm từ đặc biệt với định dạng "<từ>: "
                                const specialPhrase = optionStr.match(/^(.*?:\s*)/);

                                if (specialPhrase) {
                                    // Nếu có cụm từ đặc biệt, loại bỏ dấu phẩy ở cuối chuỗi (nếu có)
                                    const withoutTrailingComma = optionStr.replace(/,\s*$/, '');
                                    return withoutTrailingComma;
                                } else {
                                    // Nếu không có cụm từ đặc biệt, tách chuỗi thành mảng bằng dấu phẩy và loại bỏ khoảng trắng thừa
                                    return optionStr.split(',').map((item) => item.trim());
                                }
                            } else {
                                // Nếu không chứa dấu phẩy, trả về chuỗi ban đầu
                                return optionStr;
                            }
                        })
                        .flat();
                } else {
                    optionValue = [value];
                }

                dataFilter.push({
                    id,
                    input_value: optionValue,
                    title: findItem?.title,
                });
            }
        }

        const payload = {
            form: configInfo.id,
            user_preference_info: {
                detail_preferences: dataFilter,
            },
            bot: configInfo?.bot,
            user_contact_info: {
                fullname: data?.fullName || infoUser?.fullname,
                email: infoUser?.email,
                phone_number: data.phone,
            },
        };
        const jwt = jwtToken?.slice(1, -1);

        try {
            const dataResponse = await axios.post(
                `${process.env.REACT_APP_API_V3_URL}/api/v3/personal-preference`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                },
            );
            if (dataResponse.status === 200 || dataResponse.status === 201) {
                const newData = {
                    ...infoUser,
                    isSend: true,
                    data_id: dataResponse?.data?.id,
                    fullName: data.fullName,
                    phone: data.phone,
                    isLogin: true,
                };
                // if (!iFormProfile?.isComfirm) {
                const encodedJWT = sign(
                    {
                        ...infoUser,
                        isSend: true,
                        data_id: dataResponse?.data?.id,
                        fullName: data.fullName,
                        phone: data.phone,
                        isLogin: true,
                        isList: true,
                    },
                    jwtSecret,
                );
                setUserInfo(newData);
                localStorage.setItem(`login-${typeStore}-${botId}`, JSON.stringify(encodedJWT));
                // }
                setIFormProfile({ login: false, active: false, isUser: true });
                handleStateNewChat();
                setIsTyping(false);
                setLoader(false);
                return;
            }
            if (dataResponse?.data?.email_error) {
                setLoader(false);
                setNotiText({ text: 'Email đã đăng ký trước đó', type: 'danger' });
            } else if (dataResponse?.data?.non_field_errors) {
                setNotiText({ text: dataResponse?.data?.non_field_errors[0], type: 'danger' });
            } else {
                setLoader(false);
                setNotiText({ text: 'Đã có lỗi xảy ra khi đăng kí', type: 'danger' });
            }
        } catch (error) {
            setLoader(false);
            setNotiText({ text: 'Đã có lỗi xảy ra khi đăng kí', type: 'danger' });
        }
    };

    const getDataForm = async (arr, data) => {
        const url = `v3/personal-data?id=${data?.id}&bot_id=${data?.bot}&email=${infoUser?.email}`;
        const res = await axiosClient.get(url);
        if (res.status === 200 || res.status === 201) {
        handleSetData(res.data, arr, data);
        infoUser.is_email_existed = true;
        }
    };

    // set value personalization
    const handleSetData = (resData, arr, dataForm) => {
        if (resData) {
            const { pronoun, style, detail_preferences } = resData.user_preference_info;

            if (pronoun) form.setFieldsValue({ pronoun });
            if (style && arr) {
                const findItem = arr?.find((item) => item?.label?.includes(style));
                form.setFieldsValue({ style: findItem });
            }

            if (detail_preferences?.length > 0) {
                detail_preferences.forEach((item) => {
                    const findItem = dataForm?.detail_preferences?.find((curr) => curr?.id === item?.id);
                    if (findItem?.input_value === 'CHECK_BOX' || findItem?.input_value === 'MULTIPLE_CHOICES') {
                        const findValue = convertObjToArr(findItem?.choice_values)?.find(
                            (value) => value?.label === item?.input_value,
                        );

                        if (findValue) form.setFieldsValue({ [`options-${item.id}`]: findValue });
                    } else if (findItem) {
                        form.setFieldsValue({ [`content-${item.id}`]: item?.input_value });
                    }
                });
            }
        }
    };

    // get infor user
    const getInfoUser = async () => {
        try {
            const res = await axiosClient.get(`v3/user-preference?bot_id=${botId}`);
            if (res.status <= 205) {
                setConfigInfo(res.data);
                const arr = convertObjToArr(res.data?.style);
                if (arr?.length > 0) {
                    setOptions(arr);
                }
                if (infoUser?.email) form.setFieldsValue({ email: infoUser?.email });
                getDataForm(arr, res.data);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getInfoUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) return <Loading />;

    return (
        <Row justify="start" className="w-full px-1">
            <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                // style={{
                //     background: dataBot?.botM_backgroundColor ? dataBot?.botM_backgroundColor : 'white',
                // }}
                className={`rounded-3xl max-w-[430px] shadow p-5`}
            >
                {notiText?.text && <Alert message={notiText?.text} type="error" showIcon />}
                <Form form={form} onFinish={onSubmit} layout="vertical" autoComplete="off" className="gap-4 mt-4">
                    {configInfo?.fullname_title && configInfo?.fullname_title !== 'hidden' && (
                        <Form.Item
                            label={configInfo?.fullname_title !== 'hidden' ? configInfo?.fullname_title : 'Họ và tên'}
                            name="fullName"
                            rules={[{ required: true, message: 'Vui lòng nhập họ và tên' }]}
                        >
                            <Input type="text" autoComplete="fullName" placeholder="Điền họ và tên" />
                        </Form.Item>
                    )}

                    {configInfo?.phone_number_title && configInfo?.phone_number_title !== 'hidden' && (
                        <Form.Item
                            label={
                                configInfo?.phone_number_title !== 'hidden'
                                    ? configInfo?.phone_number_title
                                    : 'Số điện thoại'
                            }
                            name="phone"
                            rules={[{ required: true, message: 'Vui lòng nhập số điện thoại' }]}
                        >
                            <Input type="number" autoComplete="phone" min="0" placeholder="Điền số điện thoại" />
                        </Form.Item>
                    )}
                    {configInfo?.detail_preferences?.map((item) => {
                        if (item.input_value === 'TEXT') {
                            return (
                                <Form.Item
                                    rules={[{ required: true, message: 'Vui lòng nhập nội dung' }]}
                                    key={item.id}
                                    label={item?.title}
                                    name={`content-${item.id}`}
                                >
                                    <Input type="text" placeholder="Nội dung" />
                                </Form.Item>
                            );
                        }

                        if (item.input_value === 'CHECK_BOX') {
                            return (
                                <Form.Item
                                    key={item.id}
                                    label={item?.title}
                                    name={`options-${item.id}`}
                                    rules={[{ required: true, message: 'Vui lòng chọn' }]}
                                >
                                    <Checkbox.Group>
                                        {convertObjToArr(item.choice_values)?.map((curr, index) => (
                                            <Checkbox className="w-full mb-2" key={index} value={curr}>
                                                {curr?.label}
                                            </Checkbox>
                                        ))}
                                    </Checkbox.Group>
                                </Form.Item>
                            );
                        }

                        if (item.input_value === 'MULTIPLE_CHOICES') {
                            return (
                                <Form.Item
                                    key={item.id}
                                    label={item?.title}
                                    name={`options-${item.id}`}
                                    rules={[{ required: true, message: 'Vui lòng chọn' }]}
                                >
                                    <Select
                                        options={convertObjToArr(item?.choice_values)}
                                        placeholder="Vui lòng chọn một trong các câu trả lời"
                                    />
                                </Form.Item>
                            );
                        }

                        if (item.input_value === 'NUMBER') {
                            return (
                                <Form.Item
                                    key={item.id}
                                    label={item?.title}
                                    name={`content-${item.id}`}
                                    rules={[{ required: true, message: 'Vui lòng nhập số' }]}
                                >
                                    <Input type="number" min={0} placeholder="Nhập số" />
                                </Form.Item>
                            );
                        }

                        return null;
                    })}

                    <Form.Item>
                        <Button
                            loading={loader}
                            type="primary"
                            htmlType="submit"
                            className="w-full bg-primary"
                            style={{
                                background:
                                    dataBot?.preview_send_button_color !== '#fffff' &&
                                    dataBot?.preview_send_button_color,
                            }}
                        >
                            Xác nhận
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default StylePersonzalion;
