import { Button, Drawer, Form, Input, Space } from 'antd';
import React from 'react';

const DrawerChidren = ({ onChildrenDrawerClose, childrenDrawer, handleCreate, loading }) => {
    const [form] = Form.useForm();
    return (
        <Drawer
            width={490}
            closable={true}
            onClose={onChildrenDrawerClose}
            open={childrenDrawer}
            extra={
                <Space>
                    <Button onClick={onChildrenDrawerClose}>Huỷ bỏ</Button>
                    <Button loading={loading} htmlType="submit" onClick={() => form.submit()} type="primary">
                        Xác nhận
                    </Button>
                </Space>
            }
        >
            <Form form={form} layout="vertical" onFinish={handleCreate}>
                <Form.Item
                    name="bot_name"
                    label="Tên bot"
                    tooltip="Tên bot hiển thị với người dùng & trong cài đặt"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập tên bot',
                        },
                    ]}
                >
                    <Input placeholder="Ví dụ: Trợ lý ảo" />
                </Form.Item>
                <Form.Item
                    name="user_api_key"
                    label="OpenAI Key Embedding"
                    tooltip="API key của OpenAI"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập api key',
                        },
                    ]}
                >
                    <Input placeholder="******" />
                </Form.Item>
                <Form.Item
                    name="api_key_provider"
                    label="API Key của LLM"
                    tooltip="API key của LLM"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập api key',
                        },
                    ]}
                >
                    <Input placeholder="******" />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default DrawerChidren;
