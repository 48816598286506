import { useEffect, useState } from 'react';

const Typing = ({ noText = false, color, setting }) => {
    const [text, setText] = useState('');

    useEffect(() => {
        const timeOut1 = setTimeout(() => {
            setText('Bot đang phân tích câu hỏi...');
        }, 3000);
        const timeOut2 = setTimeout(() => {
            setText('Bot đang tra cứu thông tin...');
        }, 6000);

        const timeOut3 = setTimeout(() => {
            setText('Bot đang chuẩn bị nội dung...');
        }, 9000);

        return () => {
            clearTimeout(timeOut1);
            clearTimeout(timeOut2);
            clearTimeout(timeOut3);
        };
    }, []);

    return (
        <div>
            <div className={`flex items-center gap-1 ${!setting ? 'mr-0' : 'mr-5 '} `}>
                <div
                    className="bg-[#F5F5F5]  py-1.5 flex items-center rounded-3xl px-3 "
                    style={{
                        backgroundColor: color ? color : '#F5F5F5',
                        color:'red'
                    }}
                >
                    <div className="loaderTyping">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                {!noText && <div className="text-xs sm:text-[11px] italic font-light text-gray-400 ">{text}</div>}
            </div>
        </div>
    );
};

export default Typing;
