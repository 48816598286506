import React from 'react';

const IconTab = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path
                d="M9.77561 0.999908H7.45278C7.31568 0.999908 7.20519 1.11183 7.20519 1.25072C7.20519 1.3896 7.31568 1.50152 7.45278 1.50152H9.77561C9.88609 1.50152 9.99125 1.52714 10.0858 1.57164L4.67737 7.0503C4.5802 7.14873 4.5802 7.3065 4.67737 7.40493C4.77454 7.50337 4.93029 7.50337 5.02746 7.40493L10.4358 1.92628C10.4811 2.02202 10.5064 2.12989 10.5064 2.24181V4.58672C10.5064 4.72561 10.6169 4.83753 10.754 4.83753C10.8911 4.83753 11.0016 4.72561 11.0016 4.58672V2.24181C11.0016 1.55546 10.4532 0.999908 9.77561 0.999908Z"
                fill="#3071FF"
                stroke="#3071FF"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M10.5069 9.75803C10.5069 10.1666 10.1795 10.4983 9.77615 10.4983H2.22597C1.82264 10.4983 1.49518 10.1666 1.49518 9.75803V2.2419C1.49518 1.83333 1.82264 1.50161 2.22597 1.50161H5.33017C5.46728 1.50161 5.57776 1.3897 5.57776 1.25081C5.57776 1.11192 5.46728 1 5.33017 1H2.22597C1.54843 1 1 1.55555 1 2.2419V9.75803C1 10.4444 1.54843 10.9999 2.22597 10.9999H9.77482C10.4524 10.9999 11.0008 10.4444 11.0008 9.75803V6.73755C11.0008 6.59867 10.8903 6.48675 10.7532 6.48675C10.6161 6.48675 10.5056 6.59867 10.5056 6.73755V9.75803H10.5069Z"
                fill="#3071FF"
                stroke="#3071FF"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
        </svg>
    );
};

export default IconTab;
