import sign from 'jwt-encode';
import React, { useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import { Button, Col, Form, Input, Row, Typography, Alert } from 'antd';
import axios from 'axios';
const { Text } = Typography;

const LoginPersonalization = ({ botId, dataBot = null, setIFormProfile, typeStore = 'embedding', setTwtTokens }) => {
    const [form] = Form.useForm();
    // const { id: botId } = useParams();
    const [isLoading, setIsLoading] = useState(null);
    const [otp, setOtp] = useState('');
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [isOtpSending, setIsOptSending] = useState(false);
    const [loader, setLoader] = useState(false);
    const [notiText, setNotiText] = useState('');
    const [notiTextSuccess, setNotiTextSuccess] = useState('');

    const jwtSecret = process.env.REACT_APP_JWTSECRET;

    const handleChange = (value) => {
        setOtp(value);
    };

    const onSubmit = async (values) => {
        setLoader(true);
        const payload = {
            bot_id: botId,
            email: values.email,
        };

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_V3_URL}/api/auth/otp-v2`, payload);
            if (res?.status <= 205) {
                setLoader(false);
                setIsOptSending(true);
                setMinutes(1);
                setSeconds(120);
                setNotiTextSuccess('Mã OTP đã được gửi về mail của bạn');
                setNotiText('');
                return;
            }

            if (res.data?.detail) {
                setLoader(false);
                setNotiText(res.data?.detail);
                setNotiTextSuccess('');
            } else {
                setLoader(false);
                setNotiText('Có lỗi xảy ra khi gửi mã');
                setNotiTextSuccess('');
            }
        } catch (error) {
            setLoader(false);
            if (error?.response?.data?.email) {
                form.setFields([
                    {
                        name: 'email',
                        errors: [error?.response?.data?.email],
                    },
                ]);
            } else if (error?.response?.data?.detail) {
                setNotiText(error?.response?.data?.detail);
                setNotiTextSuccess('');
            } else {
                setNotiText('Có lỗi xảy ra khi gửi mã');
                setNotiTextSuccess('');
            }
        }
    };

    const handleLogin = async () => {
        if (otp?.length < 6 && otp?.length > 6) return;
        setIsLoading(true);
        const payload = {
            otp_code: +otp,
            bot_id: botId,
            email: form.getFieldValue('email'),
        };
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_V3_URL}/api/auth/otp-v2/verify`, payload);
            if (res.status === 200 || res.status === 201) {
                setIsLoading(false);
                const userInfo = {
                    fullName: '',
                    email: form.getFieldValue('email'),
                    phone: '',
                    is_email_existed: res?.data?.is_email_existed,
                    isSend: false,
                    data_id: res?.data?.user_info_id,
                    conversation_id: res?.data?.conversation_id,
                    isLogin: false,
                    isActiveEmail:false,
                    isActivePackage: false,
                };
                setIFormProfile({ login: false, active: true, isUser: true });
                const encodedJWT = sign(userInfo, jwtSecret);

                localStorage.setItem(
                    typeStore === 'preview'
                        ? `jwtp-${botId}`
                        : typeStore === 'debug'
                        ? `jwt-debug-${botId}`
                        : `jwt-${botId}`,
                    JSON.stringify(res.data?.access_token || ''),
                );
                if (setTwtTokens) {
                    setTwtTokens(res.data?.access_token);
                }

                localStorage.setItem(`login-${typeStore}-${botId}`, JSON.stringify(encodedJWT));
            } else {
                setOtp('');
                setIsLoading(false);
                setNotiText('Mã OTP không đúng hoặc đã hết hạn');
                setNotiTextSuccess('');
            }
        } catch (error) {
            setIsLoading(false);
            setNotiText('Mã OTP không đúng hoặc đã hết hạn');
            setNotiTextSuccess('');
            setOtp('');
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(180);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line
    }, [seconds]);

    return (
        <Row justify="start" sm={{ justify: 'center' }} className="px-1">
            <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                className={`rounded-3xl bg-white p-5 max-w-[430px] shadow`}
            >
                {notiText && <Alert message={notiText} type="error" showIcon />}
                {notiTextSuccess && <Alert message={notiTextSuccess} type="success" showIcon />}
                <Form form={form} onFinish={onSubmit} layout="vertical" className="mt-[20px]">
                    <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Vui lòng nhập email' }]}>
                        <Input type="email" placeholder="@gmail.com" className="" />
                    </Form.Item>
                    <Form.Item label="Mã OTP" className="relative">
                        <OTPInput
                            onChange={(e) => handleChange(e.trim())}
                            value={otp}
                            numInputs={6}
                            containerStyle="justify-between"
                            inputStyle="otpStyleConfig"
                            renderInput={(props) => <Input style={{ width: 32 }} type="number" {...props} />}
                        />
                        <Button
                            type="link"
                            onClick={() => form.submit()}
                            disabled={(minutes !== 0 && seconds !== 0) || loader}
                            className={`text-sm absolute -top-8 -right-4 ${
                                (minutes !== 0 && seconds !== 0) || loader
                                    ? 'text-gray-400 hover:text-gray-400'
                                    : 'text-blue-500 hover:text-blue-700'
                            }`}
                        >
                            {isOtpSending ? 'Gửi lại mã' : 'Gửi mã'}
                        </Button>
                    </Form.Item>
                    {(seconds > 0 || minutes > 0) && isOtpSending && (
                        <Text type="secondary" className="text-sm text-center mt-4">
                            Mã OTP hết hiệu lực trong : {seconds} giây
                        </Text>
                    )}

                    <Form.Item className="mt-[18px]">
                        <Button
                            block
                            type="primary"
                            onClick={handleLogin}
                            disabled={!(otp && otp?.length === 6)}
                            loading={isLoading}
                            className="bg-primary"
                            style={{
                                background:
                                    dataBot?.preview_send_button_color !== '#fffff' &&
                                    dataBot?.preview_send_button_color,
                            }}
                        >
                            Xác nhận
                        </Button>
                    </Form.Item>

                    {(dataBot?.personal_mode?.includes('1') || dataBot?.personal_mode?.includes(1)) && (
                        <Form.Item className="mt-[18px]">
                            <Button
                                block
                                onClick={() => {
                                    setIFormProfile({ login: false, active: true, isUser: false, isComfirm: true });
                                }}
                                className="bg-white"
                                htmlType="button"
                            >
                                Xác thực người dùng
                            </Button>
                        </Form.Item>
                    )}
                </Form>
            </Col>
        </Row>
    );
};

export default LoginPersonalization;
