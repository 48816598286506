import { Affix, Button, Modal, Space } from 'antd';
import React, { useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { FaTable } from 'react-icons/fa';
import { Table, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const { Search } = Input;
const TableProduct = React.memo(({ classname, classNameItem, data }) => {
    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [container, setContainer] = React.useState(null);

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const filteredData = data.filter((item) =>
        Object.keys(item).some((key) => String(item[key]).toLowerCase().includes(searchText.toLowerCase())),
    );
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const titleMap = {
        contact: 'Liên hệ',
        branch_name: 'Chi nhánh',
        product_name: 'Tên sản phẩm',
        year: 'Năm',
        version: 'Phiên bản',
        category: 'Phân loại',
        engine: 'Động cơ',
        price: 'Giá',
        promotion: 'Khuyến mãi',
    };

    const columns = useMemo(() => {
        if (data.length === 0) return [];

        const keys = Object.keys(data[0]);
        return keys.map((key) => ({
            title: titleMap[key] || key.toUpperCase(),
            dataIndex: key,
            key: key,
            render: (value) => {
                if (key === 'price') {
                    return `${value.toLocaleString()} VND`;
                }
                if (key === 'promotion') {
                    return value ? value : 'Không có';
                }
                return value;
            },
        }));
    }, [data, titleMap]);

    return (
        <div className={twMerge('mt-2 mb-1', classname)}>
            <Button
                type="text"
                className={twMerge('bg-white rounded-lg text-gray-700 hover:!bg-gray-50', classNameItem)}
                size="small"
                onClick={() => setOpen(true)}
            >
                <Space>
                    <FaTable className="text-gray-700" />
                    Dữ liệu tham khảo
                </Space>
            </Button>
            <Modal
                title="Bảng dữ liệu tham khảo"
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={1200}
                footer={null}
            >
                <div ref={setContainer} className="h-[calc(100vh-10rem)] overflow-auto thin-scroll pt-2">
                    <Affix offsetTop={10} target={() => container}>
                        <Search
                            style={{ marginBottom: 16, width: 300 }}
                            className="bg-gray-100 rounded-full "
                            enterButton={<Button shape="circle" type="link" icon={<SearchOutlined />} />}
                            variant="borderless"
                            placeholder="Tìm kiếm..."
                            allowClear
                            onSearch={handleSearch}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </Affix>

                    <Table
                        size="small"
                        style={{ width: '100%', minWidth: 1120 }}
                        columns={columns}
                        dataSource={filteredData}
                        pagination={false}
                    />
                </div>
            </Modal>
        </div>
    );
});

export default TableProduct;
