import React from 'react';
import { twMerge } from 'tailwind-merge';
import logoEmbed from '../../../../assets/images/logoembed.svg';
import Typing from '../../Typing';
import { Avatar } from 'antd';
import MarkdownRender from '../../../base/markdown';

const FirsMesenger = ({ setting, dataBot, dataHistory }) => {
    return (
        <div className={` flex gap-2 m-3 mt-[20px] space-y-5  max-w-full ${!setting?.answer ? 'hidden' : 'block'}`}>
            <div className="flex flex-col-reverse w-[35px]">
                <Avatar size={30} src={dataBot?.preview_logo ? dataBot?.preview_logo : logoEmbed} alt="logo mindmaid" />
            </div>
            <div
                className={twMerge(
                    'self-start bg-[white] w-fit rounded-3xl px-4 py-2.5  shadow',
                    !setting?.answer ? 'p-0' : 'bg-[#F5F5F5]',
                )}
                style={{
                    background: dataBot?.botM_backgroundColor,
                    marginTop: '0px',
                }}
            >
                <div className={twMerge('flex flex-col gap-1 text-[14px] font-light', !setting?.answer && 'm-0')}>
                    {setting?.answer
                        ? setting?.answer && (
                              <div
                                  style={{
                                      color: dataBot?.botM_color && dataBot?.botM_color,
                                  }}
                              >
                                  <MarkdownRender childrenRender={setting?.answer} />
                              </div>
                          )
                        : dataHistory?.length === 0 &&
                          !setting?.answer && (
                              <div className="flex items-center gap-2">
                                  <img
                                      className="w-[32px] h-[32px] rounded-full"
                                      src={dataBot?.preview_logo ? dataBot?.preview_logo : logoEmbed}
                                      alt="logo mindmaid"
                                  />
                                  <Typing noText color={dataBot?.botM_backgroundColor} />
                              </div>
                          )}
                </div>
            </div>
        </div>
    );
};

export default FirsMesenger;
