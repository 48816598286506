import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axiosClient from '../../../api/axiosClient';
import { twMerge } from 'tailwind-merge';
import ReactLoading from 'react-loading';
import InputController from '../../base/inputController';

const EmbedLoginEmail = ({
    logo,
    botId,
    setIForm,
    scrollToBottom,
    updateLastAnswer,
    setIsTyping,
    isPrevew = false,
}) => {
    const {
        control,
        handleSubmit,
        formState: { errors, isDirty },
        setValue,
        watch,
        reset,
    } = useForm({
        defaultValues: { email: '', otp_code: '', name: '' },
    });
    const [iotp, setIotp] = useState(false);
    const [iloading, setIloading] = useState(false);
    const [message, setMessage] = useState({ error: '', noti: '' });
    let iSendOTP = false;
    const [countdown, setCountdown] = useState(120);
    const [isActivated, setIsActivated] = useState(false);

    const onsubmit = async (data) => {
        if (isActivated && iSendOTP) return;
        setIloading(true);
        const payload = {
            bot_id: botId,
            email: data.email,
            ...(iotp && !iSendOTP && { otp_code: data?.otp_code, name: data?.name }),
        };
        const url = iotp && !iSendOTP ? '/passport' : '/send-otp-mail';

        try {
            const res = await axiosClient.post(url, payload);
            if (res.status <= 205) {
                setIloading(false);
                if (iSendOTP) {
                    setIotp(true);
                    setIsActivated(true);
                    setCountdown(120);
                    setMessage({ noti: 'Đã gửi otp tới email của bạn', error: '' });
                } else {
                    setIotp(false);
                    setIForm(false);
                    setIsTyping(false);
                    updateLastAnswer(res?.data?.detail);
                    if (res?.data?.access_token) {
                        const parsedBotIds = res.data.access_token;
                        if (isPrevew) {
                            localStorage.setItem(`jwtp-${botId}`, JSON.stringify(parsedBotIds));
                            localStorage.setItem(`isloginPackage-${botId}`, true);
                        } else {
                            localStorage.setItem(`jwt-${botId}`, JSON.stringify(parsedBotIds));
                            localStorage.setItem(`Embed-isloginPackage-${botId}`, true);

                        }
                    }
                }
            } else {
                setIloading(false);
                if (res.data?.detail?.includes('Mã OTP không chính xác')) {
                    iSendOTP = false;
                    return;
                }

                if (res.data?.detail) {
                    setMessage({ noti: '', error: res.data?.detail });
                }
            }
            scrollToBottom();
        } catch (error) {
            setIloading(false);
            message.error('Bạn không thể đang nhập email')
        }
    };

    useEffect(() => {
        let intervalId;

        if (isActivated) {
            intervalId = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000); // Cập nhật mỗi giây
        }

        if (countdown === 0) {
            setIsActivated(false);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [isActivated, countdown]);


    return (
        <div className="flex items-center gap-1 px-3">
            <div className="flex flex-col-reverse w-[35px] sm:hidden">
                <img className="w-[32px] h-[32px] rounded-full" src={logo} alt="logo mindmaid" />
            </div>
            <form
                onSubmit={handleSubmit(onsubmit)}
                className={twMerge('p-4 shadow-md rounded-xl bg-white w-full max-w-[400px]', isPrevew && 'max-w-[400px]')}
            >
                {/* <h3 className="text-lg text-left text-[#3071FF] font-semibold text-center">Xác thực người dùng</h3> */}

                <div className="flex flex-col gap-[6px]">
                    <div className="text-[0.875rem] font-medium font-inter text-gray-800 flex items-center gap-2">
                        Email
                    </div>
                    <InputController
                        control={control}
                        name="email"
                        errors={errors}
                        required
                        placeholder="Nhập email của bạn"
                        className="w-full h-10] px-4 transition-all ease-in-out border font-light rounded-md outline-none duration-400 text-[0.875rem]"
                        type="email"
                    />

                    {iotp && (
                        <>
                            <div className="text-[0.875rem] mt-2 font-medium font-inter text-gray-800 flex items-center gap-2">
                                Họ và tên
                            </div>
                            <InputController
                                control={control}
                                name="name"
                                errors={errors}
                                required
                                placeholder="Nhập họ và tên"
                                className="w-full h-10] px-4 transition-all ease-in-out border font-light rounded-md outline-none duration-400 text-[0.875rem]"
                                type="text"
                            />

                            <div className="text-[0.875rem] justify-between mt-2 font-medium font-inter text-gray-800 flex items-center gap-2">
                                OTP
                                <button
                                    onClick={() => (iSendOTP = true)}
                                    type="submit"
                                    className="text-sm font-light cursor-pointer text-primary"
                                >
                                    {isActivated ? `Gửi lại sau: ${countdown} Giây` : 'Gửi lại'}
                                </button>
                            </div>

                            <InputController
                                control={control}
                                name="otp_code"
                                errors={errors}
                                required
                                placeholder="Nhâp OTP code"
                                className="w-full h-10] px-4 transition-all ease-in-out border font-light rounded-md outline-none duration-400 text-[0.875rem]"
                                type="number"
                            />
                        </>
                    )}
                    {message?.error && <p className="text-nomarl1 text-danger font-light">{message?.error}</p>}
                    {message?.noti && <p className="text-nomarl1 text-green-500 font-light">{message?.noti}</p>}
                </div>

                <button
                    onClick={() => (iotp ? (iSendOTP = false) : (iSendOTP = true))}
                    className={twMerge(
                        'flex items-center mt-4 font-light justify-center h-10  w-full bg-primary  text-[0.875rem] text-white  rounded-[6px] cursor-pointer ',
                    )}
                >
                    {iloading ? (
                        <ReactLoading type="spin" width={24} height={24} color="white" />
                    ) : iotp ? (
                        'Gửi mã OTP'
                    ) : (
                        'Lấy mã OTP'
                    )}
                </button>
            </form>
        </div>
    );
};

export default EmbedLoginEmail;
